<template>
  <section class="py-5 px-2"> 
      <div id="opener" class='col-md-8 col-lg-6 mx-auto mb-5'>
        <h2 class="">{{$t('register-opener')}}</h2>
        <!-- <p style="font-weight: normal; font-size: 1.1rem">Most users sign up in about 90 seconds.<br/>Can you do it faster?</p> -->
      </div>

      <form v-if="!isLoggedIn" class="form-signin mx-auto my-5 py-5 px-3 br-16 text-start shadow">
        <!-- <p class='text-end' style="color:#dc3545">*Required</p> -->

        <div class='col-md-10 mx-auto'>
          <div class="progress col-md-8 my-4 mx-auto">
            <div id='sign-up-steps-bar' class="progress-bar" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">1/3</div>
          </div>
        </div>

        <!-- Screen one -->
        <div id='step-one' class='col-md-10 mx-auto'>
          <h4 class='chat-text my-4'>{{ $t('register-chat-1') }}</h4>
          <h5>{{$t('register-step-1-name')}}</h5>
          <div class="col-md-8 mx-auto">
            <label for="firstName">{{$t('register-step-1-prompt-1')}}</label>
            <input type="text" class="form-control" id="firstName" v-model="firstName" placeholder="Savitribai" value="" required onkeypress="return event.charCode != 32">
            <p class='text-end'><small>{{$t('register-step-1-prompt-2')}}</small></p>
            <div class="invalid-feedback">Please input a valid value.</div>
          </div>
          <div class="col-md-6 mx-auto my-4 text-center">
            <button class='btn btn-primary' id='next-button' @click="enableStepMid" disabled aria-controls="step-mid">{{$t('register-step-1-next')}}</button>
          </div>
        </div>

        <!-- Screen mid -->
        <div id='step-mid' class="col-md-10 mx-auto">
          <h4 class='chat-text my-4'>Hello, {{this.firstName}}{{ $t('register-chat-2') }}</h4>
          
          <div id="step-mid-q1">
            <h5>{{ $t('register-mid-1') }}</h5>
            <div class='row row-cols-2'>
              <div class='col d-flex justify-content-center my-1'><button class='btn btn-outline-warning professions' id="prof-Teacher" @click="setProfession('Teacher')" tabindex="0">{{ $t('register-professions-1') }}</button></div>
              <div class='col d-flex justify-content-center my-1'><button class='btn btn-outline-warning professions' id="prof-Parent" @click="setProfession('Parent')" tabindex="0">{{ $t('register-professions-2') }}</button></div>
              <div class='col d-flex justify-content-center my-1'><button class='btn btn-outline-warning professions' id="prof-Student" @click="setProfession('Student')" tabindex="0">{{ $t('register-professions-3') }}</button></div>
              <div class='col d-flex justify-content-center my-1'><button class='btn btn-outline-warning professions' id="prof-Curious" @click="setProfession('Curious')" tabindex="0">{{ $t('register-professions-4') }}</button></div>
            </div>
          </div>

          <div id="step-mid-q2" class='my-4'>
            <h5>{{ $t('register-mid-2') }}</h5>
            <div class="col-md-10 my-3 mx-auto">
              <label for="userQuery"><strong>{{ $t('register-mid-2-prompt-1') }}</strong></label>
              <input type="text" class="form-control" id="userQuery" v-model="userQuery" placeholder="I think..." value="">
              <p class='text-end'><small>{{ $t('register-mid-2-prompt-2') }}</small></p>
              <div class="invalid-feedback">Please answer this question!</div>
              <p class='my-2'>{{ $t('register-mid-2-prompt-3') }}</p>
            </div>
            
            <div class="col-md-6 mx-auto text-center">
              <button class='btn btn-primary' id='next-two-button' @click="enableStepTwo" disabled aria-controls="step-two">{{$t('register-mid-2-next')}}</button>
            </div>
          </div>
        </div>

        <!-- Sign up options -->
        <div id="step-two" class='col-md-10 mx-auto my-5'>
          <div class='col my-5'>
            <h4 class='chat-text my-4'>{{ $t('register-chat-3-one') }}, {{this.firstName}}{{ $t('register-chat-3-two') }}</h4>
            <h5>{{ $t('register-step-two-prompt-1') }}</h5>
            <div class='row row-cols-1 row-cols-md-3'>
              <div class='col d-flex justify-content-center'>
                <button class='btn mx-1 my-2' id="google-signin" @click="loginGoogle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16"><path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/></svg>
                  <span id='google-signin-text'> {{ $t('register-google') }}</span>
                  <div id="google-signin-spinner" class="spinner-border d-none" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> 
                </button>
              </div>
              <div class='col d-flex justify-content-center'>
                <button class='btn mx-1 my-2' id="facebook-signin" @click="loginFacebook"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>
                  <span id='facebook-signin-text'> {{ $t('register-facebook') }}</span>
                  <div id='facebook-signin-spinner' class="spinner-border d-none" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> 
                </button>
              </div>
              <div class='col d-flex justify-content-center'>
                <button class='btn btn-outline-dark mx-1 my-2' id="email-option" @click="emailSignUp" aria-controls="email-signup"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16"><path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/></svg> {{ $t('register-email') }}</button>
              </div>
            </div>
            <p class='my-4 text-center'><small>{{ $t('register-step-two-prompt-2') }} 😄</small></p>
          </div>

          <!-- Email & Password fields -->
          <div id="email-signup" class='col-md-6 offset-md-3 my-2 d-none'>
            <div class="mb-3">
              <label for="email">{{$t('register-email-input')}}</label>
              <input type="email" class="form-control" id="email" placeholder="savitribai@gmail.com" v-model="email" required onkeypress="return event.charCode != 32">
              <div class="invalid-feedback">
                Please enter a valid email address.
              </div>
            </div>
            <div class="mb-3">
              <label for="password">{{$t('register-password-input')}}</label>
              <input type="password" class="form-control" id="password" placeholder="Must contain at least 6 characters" v-model="password" required>
              <div class="invalid-feedback">
                Please ensure you're using a secure password.
              </div>
              <div class="checkbox mb-3">
                <label class='text-muted'>
                  <input id="toggle-password" @click="togglePassword()" type="checkbox" aria-label="Show password as plain text. Warning: this will display your password on the screen."> {{$t('show-password')}}
                </label>
              </div>
              <div id="password-length-prompt" class='d-none' style="color:#dc3545">
                {{$t('password-length')}}
              </div>
              <div id="forgot-password-prompt" class='d-none' style="color:#dc3545">
                {{$t('existing-account')}}
                <!-- <router-link to="/forgot">Did you forget your password?</router-link> -->
              </div>
            </div>
            <div class="col-md-6 mx-auto text-center">
              <button @click="register" id="register-button" class="btn btn-lg btn-primary" type="submit" disabled>
                <span id='register-button-text'>{{$t('sign-up-button')}}</span>
                <div id="email-signin-spinner" class="spinner-border d-none" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> 
              </button>
            </div>
          </div>
        </div>
      </form>   

      
      <div id='policy-note' class='col-md-8 mx-auto'>
        <hr class='mx-auto' />
        <p class='text-muted col-lg-8 mx-auto'>
          {{$t('register-disclaimer')}}
        </p>
      </div>
    
    <div v-if="isLoggedIn" class='space-out'>
      <h2>Hey there, {{ userFirstName }}!</h2>
      <h3>You're already logged in.</h3>
      <br>
      <h3>Want to see your <router-link to="/diary">Diary</router-link> instead?</h3>
    </div>
  </section>
</template>

<script>
// import firebase from 'firebase';
import firebase from 'firebase/app';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { mapState } from 'vuex'
import $ from 'jquery';

export default {
  name: 'register',
  metaInfo: {
    title: 'Register',
    titleTemplate: '%s | Chachi',
  },
  data: function() {
    return {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      state: '',
      profession: '',
      userQuery: '',
      professions: ["Teacher", "Principal", "Student", "Parent", "NGO professional", "Curious person"],
      states: ["Outside of India", 'Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu', 'Jharkhand', 'Karnataka', 'Kashmir', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal']
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      userFirstName: state => state.currentUser.firstName
    })
  },
  watch: {
    // whenever the field changes, this function will run
    firstName: function () {
      this.enableNextButton()
    },
    userQuery: function () {
      this.enableFinalButton()
    },
    password: function () {
      this.checkPassword()
    }
  },
  methods: {
    enableNextButton() {
      if (this.firstName.length > 0) {
        document.getElementById('next-button').disabled = false
      } else {
        document.getElementById('next-button').disabled = true
        $('#step-mid').hide()
        $('#step-two').hide()
      }
    },
    enableFinalButton() {
      if (this.firstName.length > 0 && this.userQuery.length > 0) {
        document.getElementById('next-two-button').disabled = false
      } else {
        document.getElementById('next-two-button').disabled = true
        // $('#step-mid').hide()
        $('#step-two').hide()
      }
    },
    enableStepMid() {
      $('#step-one').fadeOut(400)
      $('#sign-up-steps-bar').text('2/3')
      $('#sign-up-steps-bar').css('width','66%')
      $('#step-mid').fadeIn(600)
      // $('#step-mid').toggleClass('d-none')
      // document.getElementById('step-mid').classList.remove('d-none')
    },
    enableStepTwo() {
      $('#step-mid').fadeOut(400)
      $('#sign-up-steps-bar').text('3/3')
      $('#sign-up-steps-bar').css('width','100%')
      $('#step-two').fadeIn(600)
      // document.getElementById('step-two').classList.remove('d-none')
    },
    setProfession(prof) {
      this.profession = prof
      // remove active class from all professions
      $('.professions').removeClass('active')
      // add active class to the selected button
      $('#prof-'+prof).addClass('active')
      $('#step-mid-q2').show()
    },
    emailSignUp() {
      document.getElementById('email-signup').classList.toggle('d-none')
    },
    checkPassword() {
      if (this.password.length < 6) {
        document.getElementById('password-length-prompt').classList.remove('d-none')
        document.getElementById('register-button').disabled = true
      } else {
        document.getElementById('password-length-prompt').classList.add('d-none')
        document.getElementById('register-button').disabled = false
      }
    },
    register: function(e) {
      let self = this
      if (this.email.length < 1 || this.password.length < 8) {
        this.checkPassword()
        return
      }
      document.getElementById('register-button-text').classList.add('d-none')
      document.getElementById('email-signin-spinner').classList.remove('d-none')
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .then(
          user => {
            user
            self.createDBrecord(firebase.auth().currentUser.uid, this.email, this.firstName)
            // alert(`Account Created for ${user.user.email}`);
            this.$router.push('/login')
            // this.$router.go({ path: this.$router.path });
          },
          err => {
            if (err.code === "auth/email-already-in-use") {
              document.getElementById('forgot-password-prompt').classList.remove('d-none')
            }
            alert(err.message);
          }
        );
      e.preventDefault();
    },
    loginGoogle() {
      let self = this
      document.getElementById('google-signin-text').classList.add('d-none')
      document.getElementById('google-signin-spinner').classList.remove('d-none')
      const provider = new firebase.auth.GoogleAuthProvider();  
      firebase.auth().signInWithPopup(provider)
        .then(async user => {    
          user  
          // alert(`You are logged in as ${user.user.email}`);
          // this.$store.dispatch("setUserJWT")
          self.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
          // this.$store.dispatch("setCurrentUser")
          this.$router.push('/login')
        })
    },
    loginFacebook() {
      let self = this
      document.getElementById('facebook-signin-text').classList.add('d-none')
      document.getElementById('facebook-signin-spinner').classList.remove('d-none')
      const provider = new firebase.auth.FacebookAuthProvider();  
      firebase.auth().signInWithPopup(provider)
        .then(async user => {      
          user
          // alert(`You are logged in as ${user.user.email}`);
          // this.$store.dispatch("setUserJWT")
          self.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
          // this.$store.dispatch("setCurrentUser")
          this.$router.push('/login')
        })
    },
    togglePassword() {
      const passwordInput = document.getElementById('password');
      const togglePasswordButton = document.getElementById('toggle-password');

      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        togglePasswordButton.textContent = 'Hide password';
        togglePasswordButton.setAttribute('aria-label',
          'Hide password.');
      } else {
        passwordInput.type = 'password';
        togglePasswordButton.textContent = 'Show password';
        togglePasswordButton.setAttribute('aria-label',
          'Show password as plain text. ' +
          'Warning: this will display your password on the screen.');
      }
    },
    createDBrecord(uid, email, firstName) {
      // when a user signs up, create a record in the db
      var payload = {
        'uid': uid,
        'email': email,
        'firstName': firstName,
        'lastName': this.lastName,
        'state': this.state,
        'profession': this.profession,
        'preferredLanguage': this.$store.state.lang,
        'proUser': '',
        'problem': this.userQuery
      }
      // console.log(payload)
      payload = this.toQueryString(payload)
      // console.log(payload)
      const token = this.$cookies.get('user-jwt')
      const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
      var api_link = 'https://api.chachi.app/v2/users/create' + payload
      axios.put(api_link, {}, config)
        .then( response => {
          if (response.data.status === "Success") { console.log("User created successfully.") }
          else { console.log("Method failed. User could not be created in DB.")}
          return true;
        })
        .catch (err => {
          if (err.response.data.detail === "Invalid token or expired token.") {
            var newTokenSet = this.$store.dispatch('setUserJWT')
            if (newTokenSet) {this.createDBrecord(this.uid)}
          }
        })
    },
    toQueryString(payload) {
      var keys = Object.keys(payload)
      var query = '?' + keys[0] + '=' + encodeURI(this.sanitizeInput(payload[keys[0]]))
      for (var i = 1; i < keys.length; i++) {
        if (payload[keys[i]] === '') { payload[keys[i]] = 'null' }
        query = query + '&' + keys[i] + '=' + encodeURI(this.sanitizeInput(payload[keys[i]]))
      }
      return query
    },
    sanitizeInput(dirty) {
      var clean = DOMPurify.sanitize(dirty, {ALLOWED_TAGS: []});
      if (clean.indexOf(' ') > -1) {
        clean.split(' ').join('')
      }
      return clean
    }
  },
};
</script>

<style scoped>
.btn-outline-warning {
  color: var(--bs-dark);
  border-color: var(--bs-dark);
}
.form-signin {
  border: 1px solid var(--bs-dark);
  width: 100%;
	max-width: 600px;
}
.progress {
	width: 100%;
	max-width: 600px;
}
#google-signin {
  background-color: #ea4335;
  color: white;
  width: 120px;
}
#facebook-signin {
  background-color: #3b5998;
  color: white;
  width: 120px;
}
#next-button,
#email-option,
#register-button {
  width: 120px;
}
a {
  text-decoration: none;
  color: inherit;
}
#step-mid, #step-two, #step-mid-q2 {
  display: none;
}
hr {
  width: 50%
}
.chat-text {
  color: var(--bs-gray-dark)
}
.spinner-border {
  width: 1rem;
  height: 1rem;
}
#prof-Teacher,
#prof-Parent,
#prof-Student,
#prof-Curious {
  width: 136px;
}
</style>